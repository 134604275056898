import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-on-hover-tooltip',
  standalone: true,
  imports: [TranslateModule, NgClass, NgStyle, NgTemplateOutlet],
  templateUrl: './on-hover-tooltip.component.html',
  styleUrls: ['./on-hover-tooltip.component.scss'],
})
export class OnHoverTooltipComponent {
  @Input() content: string;

  @Input() backgroundColor: string;

  @Input() arrowDirection: 'top' | 'bottom' | 'left' | 'right';

  @Input() targetTemplate: any;
}
